import React from 'react';

const Logo = props => {
  return (
    <img
      style={
        {
          // marginLeft: '20px',
          // marginTop: '5px'
        }
      }
      alt="Logo"
      src={props.white ? '/static/logoWhite.svg' : '/static/logo.svg'}
      {...props}
    />
  );
};

export default Logo;
