import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/suppliers',
        component: lazy(() => import('src/views/supplier/SupplierWrapper'))
      },
      {
        exact: true,
        path: '/app/management/suppliers/details',
        component: lazy(() => import('src/views/supplier/SupplierDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/suppliers/edit',
        component: lazy(() => import('src/views/supplier/SupplierAddEditView'))
      },
      {
        exact: true,
        path: '/app/management/suppliers/create',
        component: lazy(props =>
          import('src/views/supplier/SupplierAddEditView')
        )
      },
      {
        exact: true,
        path: '/app/management/suppliers/:supplierId',
        component: lazy(() => import('src/views/supplier/SupplierDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/suppliers/:supplierId/edit',
        component: lazy(() => import('src/views/supplier/SupplierAddEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductWrapper'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(props => import('src/views/product/ProductAddEditView'))
      },
      {
        exact: true,
        path: '/app/management/products/edit',
        component: lazy(() => import('src/views/product/ProductAddEditView'))
      },
      {
        exact: true,
        path: '/app/management/products/details',
        component: lazy(() => import('src/views/product/ProductDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/materials',
        component: lazy(() => import('src/views/material/MaterialWrapper'))
      },
      {
        exact: true,
        path: '/app/management/materials/create',
        component: lazy(props =>
          import('src/views/material/MaterialAddEditView')
        )
      },
      {
        exact: true,
        path: '/app/management/materials/details',
        component: lazy(() => import('src/views/material/MaterialDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/materials/edit',
        component: lazy(() => import('src/views/material/MaterialAddEditView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/home" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
