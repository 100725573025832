import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CHANGE_ACTIVE_ORG } from '../../../queries/orgQueries';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 200
  }
})(props => (
  <Menu
    disableAutoFocusItem
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useAuth();
  const history = useHistory();

  const [onChangeHandler] = useMutation(CHANGE_ACTIVE_ORG);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = org => {
    onChangeHandler({
      variables: {
        id: user.id,
        org: org
      }
    });
    handleClose();
    setTimeout(() => {
      props.refetch();
    }, 200);
    history.push('/app');
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ boxShadow: 'none' }}
      >
        {props.activeOrg}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.orgs.map(org => {
          return (
            <StyledMenuItem
              key={org.id}
              disabled={org.org.name === props.activeOrg}
            >
              <ListItemText
                primary={org.org.name}
                onClick={() => handleChange(org.id)}
              />
            </StyledMenuItem>
          );
        })}

        <StyledMenuItem disabled>
          <ListItemText secondary="Add workspace (Coming soon)" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
