import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  // Button,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="secondary">
                mRecipe
              </Typography>
              <Typography variant="h1" color="textPrimary">
                Manage your supply chain like a pro
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  Whether you are a new product designer or an experienced
                  supply chain expert, mRecipe™ gives you the tools to
                  manufacture products faster, better and cheaper.
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  Between the intuitive interface and powerful data analytics
                  you'll wonder how you ever lived without it.
                </Typography>
              </Box>
              <Box mt={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h2" color="secondary">
                      Manage Costs
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Intuitive BOM tracking
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h2" color="secondary">
                      Manage Quality
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Performance Reporting
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h2" color="secondary">
                      Manage Speed
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Visualize your supply chain
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
