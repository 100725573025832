import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

const FAQS = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          Frequently asked questions
        </Typography>
        <Box my={1}>
          <Divider />
        </Box>
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={6}>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Why did we build this?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Our founder was managing a global supply chain with a small
                  team responsible for production and quality control.
                  <br />
                  <br />
                  Fielding multiple requests (mainly from sales reps and product
                  managers) for status and price updates became a full-time job.{' '}
                  <br />
                  <br />
                  None of the existing solutions were easy-to-use or implement.
                  We built this tool to make it easy to collaborate on a product
                  catalog.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Who is this tool not for?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  This is a custom-tailored solution for people who are managing
                  production of physical products. You know, the kind you can
                  hold in your hand.
                  <br />
                  <br />
                  It's not built for software developers. Software devs are
                  great, but they also have a very different set of problems to
                  deal with. And many, many good options.
                </Typography>
              </dd>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
