import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink
} from '@apollo/client';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const token = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  const idToken = token.user.idToken;

  const createApolloClient = idToken => {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://adombo.com:443/v1alpha1/graphql',
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              suppliers: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              materials: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              material_images: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              supplier_images: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              products: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              product_mat_map: {
                merge(existing, incoming) {
                  return incoming;
                }
              },
              product_images: {
                merge(existing, incoming) {
                  return incoming;
                }
              }
            }
          }
        }
      })
    });
  };

  const client = createApolloClient(idToken);

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
