import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Notifications from './Notifications';
import OrgDropdown from './OrgDropdown';

import useAuth from 'src/hooks/useAuth';
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_ORG } from '../TopBar/topBarQueries';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

const TopBar = ({ orgs, className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { loading, error, data, refetch } = useQuery(GET_ACTIVE_ORG, {
    variables: {
      id: user.id
    }
  });
  if (loading) return null;
  if (error) return null;
  const activeOrg = data.users_by_pk.active_org_map.name;
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo white="true" />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <OrgDropdown activeOrg={activeOrg} orgs={orgs} refetch={refetch} />
        <Notifications />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
