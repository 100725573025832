import { gql } from '@apollo/client';

const GET_ORGS = gql`
  {
    organization {
      id
      name
    }
  }
`;

const GET_ACTIVE_ORG = gql`
  query getActiveOrg($id: String!) {
    users_by_pk(id: $id) {
      active_org_map {
        name
      }
    }
  }
`;

const CHANGE_ACTIVE_ORG = gql`
  mutation changeActiveOrg($id: String!, $org: Int!) {
    update_users(where: { id: { _eq: $id } }, _set: { active_org: $org }) {
      affected_rows
    }
  }
`;

export { GET_ORGS };
export { GET_ACTIVE_ORG };
export { CHANGE_ACTIVE_ORG };
