import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" align="center" color="textPrimary">
          Built by and for supply chain managers
        </Typography>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>01</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Track shared components
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    One of the best ways to build products faster, cheaper and
                    better is to share components between products. The largest
                    product goods companies have mastered this, and now so can
                    you.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>02</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Speed matters
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    Sign up for a free account and start using it within
                    minutes. It's easy to use, powerful and works world-wide
                    (even in mainland China.)
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex">
                <Avatar className={classes.avatar}>03</Avatar>
                <Box ml={2}>
                  <Typography variant="h4" gutterBottom color="textPrimary">
                    Replace your DAM
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    We didn&apos;t set out to build a digital asset management
                    (DAM) system, but storing assets like artwork, firmware,
                    spec sheets, engineering drawings, gerber files, etc. just
                    makes sense wherever your multi-level BOM data is stored.
                    <br />
                    <br />
                    In fact, a lot of people just use mRecipe as a free DAM!
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
